import {
  CURATOR_PRODUCT_FILTERS,
  DEFAULTS_KEY,
  FILTER_KEY,
  getQueryFilterVariables,
  sessionStorageInitialFilters,
  STATUS_KEY,
} from "../utils/filterUtils";
import {
  CurationStatusesFilter,
  DropdownOption,
  FilterMode,
  FilterType,
  OrderBy,
  ProductFilters,
  UserRole,
} from "../types";
import {
  GetCuratorProductsResult,
  GetCuratorProductsVariables,
  ResultsByFilter,
} from "../types/curatorProductTypes";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import getCuratorAreas, {
  CuratorAreasResult,
  HasuradbDmoArea,
} from "../graphqlQueries/getCuratorAreas";
import { getHasuraRoleContext, isVisitFinlandAdmin } from "../utils/functions";
import useProductFilters, { initialProductFilters } from "../components/hooks/useProductFilters";

import BackNavigationSideBar from "../components/BackNavigationSideBar";
import Comments from "../components/comments/Comments";
import CuratorProductForm from "../components/productForm/CuratorProductForm";
import CuratorProductList from "../components/curatorWorkspace/CuratorProductList";
import { DEFAULT_PAGE_SIZE_OPTIONS } from "../utils/pagingUtils";
import FilteredListHeader from "../components/products/FilteredListHeader";
import Filters from "../components/curatorWorkspace/Filters";
import MobileFiltersModal from "../components/curatorWorkspace/MobileFiltersModal";
import MobileNavigationHeader from "../components/MobileNavigationHeader";
import Navigation from "../components/navigation/Navigation";
import Page from "./Page";
import { Paging } from "../components/Paging";
import ViewProduct from "../components/products/ViewProduct";
import ViewProductCuratorToolbar from "../components/products/ViewProductCuratorToolbar";
import buildGetAllCuratorProductsQuery from "../graphqlQueries/getAllCuratorProducts";
import buildGetDmoProductsQuery from "../graphqlQueries/getDmoProducts";
import buildGetProductsWithoutCuratorQuery from "../graphqlQueries/getProductsWithoutCurator";
import { composeResultCount } from "../utils/productFormUtils/productUtils";
import { curationStatuses } from "../utils/filterOptions";
import styled from "styled-components";
import { useIsMediumOrSmaller } from "../components/MediaQuery";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const ViewProductContainer = styled.div`
  flex-grow: 1;
  background: var(--color-white);
  height: 100%;
`;

const ProductViewer = styled.div`
  display: flex;
  height: 100%;
`;

type CuratorWorkspaceProps = {
  companyId: string;
};

type CuratorWorkspacePageProps = {
  companyId: string;
  dmoAreas: HasuradbDmoArea[];
};

const CuratorWorkspace = ({ companyId }: CuratorWorkspaceProps) => {
  const { t } = useTranslation();
  const {
    data: curatorAreasData,
    loading: curatorAreasLoading,
    error: curatorAreasError,
  } = useQuery<CuratorAreasResult>(getCuratorAreas, {
    variables: {
      id: companyId,
    },
    context: getHasuraRoleContext(UserRole.ManageCuration),
  });

  if (curatorAreasError) {
    return <p>{t("common.error")}</p>;
  }

  if (!curatorAreasData || curatorAreasLoading) {
    return null;
  }

  return <CuratorWorkspacePage companyId={companyId} dmoAreas={curatorAreasData.dmoArea} />;
};

const CuratorWorkspacePage = ({ companyId, dmoAreas }: CuratorWorkspacePageProps) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const [keycloak] = useKeycloak();
  const listElement = useRef<HTMLDivElement>(null);
  const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false);
  const [resultsByFilter, setResultsByFilter] = useState<ResultsByFilter>();
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.Ascending);
  const [selectedAmountOption, setSelectedAmountOption] = useState<DropdownOption>(
    // get the last value of page size options
    DEFAULT_PAGE_SIZE_OPTIONS.slice(-1)[0]
  );
  const [showNotification, setShowNotification] = useState<string>("");

  const onNotificationClose = useCallback(() => setShowNotification(""), []);

  const isVFAdmin = isVisitFinlandAdmin(keycloak);
  const limit = selectedAmountOption.value;

  const history = useHistory();
  const { search, pathname } = useLocation();
  const searchParams = new Map();
  search
    .substring(1) // remove ? from search string
    .split("&") // Divide different filters from string
    .forEach((it: string) => {
      const [key, val] = it.split("=");
      searchParams.set(key, val);
    });

  const sessionFilters: ProductFilters = sessionStorageInitialFilters(CURATOR_PRODUCT_FILTERS);

  const filterInitialization = () => {
    if (searchParams.has(DEFAULTS_KEY)) {
      return {
        ...initialProductFilters,
        curationStatus: searchParams.get(STATUS_KEY) as CurationStatusesFilter,
        productNotification: searchParams.get(FILTER_KEY),
      };
    }

    return {
      ...sessionFilters,
      curationStatus:
        (searchParams.get(STATUS_KEY) as CurationStatusesFilter) ||
        sessionFilters?.curationStatus ||
        initialProductFilters.curationStatus,
      productNotification: searchParams.get(FILTER_KEY) || sessionFilters?.productNotification,
    };
  };

  const { filters, setFilters, defaultFilters } = useProductFilters(filterInitialization());
  useEffect(() => {
    sessionStorage.setItem(CURATOR_PRODUCT_FILTERS, JSON.stringify(filters));
  }, [filters]);

  const resetScroll = () => listElement?.current?.scrollTo(0, 0);

  const [productsQuery, queryFilterVars] = useMemo(() => {
    const curationAreaIds = !isVFAdmin ? dmoAreas.map((area) => area.curationArea.id) : undefined;

    const filterVars = getQueryFilterVariables(filters, curationAreaIds);

    const getProductsQuery = () => {
      if (isVFAdmin) {
        if (filters.withoutCurator) {
          return buildGetProductsWithoutCuratorQuery(filterVars);
        }
        return buildGetAllCuratorProductsQuery(filterVars);
      }

      return buildGetDmoProductsQuery(filterVars);
    };

    return [getProductsQuery(), filterVars];
  }, [filters, isVFAdmin, dmoAreas]);

  const queryVariables = {
    ...queryFilterVars,
    limit,
    offset: page * limit,
    orderBy,
  };

  const [totalProductCount, setTotalProductCount] = useState(0);

  const { data: curatorProductResult, loading: productsLoading } = useQuery<
    GetCuratorProductsResult,
    GetCuratorProductsVariables
  >(productsQuery, {
    variables: queryVariables,
    context: getHasuraRoleContext(UserRole.ManageCuration),
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (curatorProductResult) {
      const results = composeResultCount(curatorProductResult);
      setResultsByFilter(results);
      if (filters.curationStatus) {
        setTotalProductCount(results[filters.curationStatus] || 0);
        history.push({
          pathname: pathname,
          search: `${STATUS_KEY}=${filters.curationStatus}${
            filters.productNotification ? `&${FILTER_KEY}=${filters.productNotification}` : ""
          }`,
        });
      }
    }
  }, [
    curatorProductResult,
    limit,
    filters.curationStatus,
    history,
    pathname,
    filters.productNotification,
    totalProductCount,
  ]);

  useEffect(() => {
    resetScroll();
  }, [filters]);

  const isEditRoute = !!useRouteMatch<{ id: string }>(`${path}/edit/:id`)?.params;
  const idParam = useRouteMatch<{ id: string }>(`${path}/:id`)?.params;
  const selectedProductId = idParam?.id;
  const isMedium = useIsMediumOrSmaller();
  const showMobileNavigation = selectedProductId && isMedium;
  const showProductList = !isMedium || (isMedium && !selectedProductId);
  const products = curatorProductResult?.products;
  const productsLength = products?.length ?? 0;

  return (
    <Page
      header={showMobileNavigation ? <MobileNavigationHeader backUrl={path} /> : null}
      sideBar={
        isEditRoute ? (
          <BackNavigationSideBar backPath={pathname.replace("edit/", "")} />
        ) : (
          <Navigation
            filterComponent={
              <Filters
                initialFilters={{
                  ...initialProductFilters,
                  cities: undefined,
                  productCategories: undefined,
                }}
                filterType={FilterType.DesktopFilters}
                filters={filters}
                onFilterChange={(newFilters) => {
                  setFilters(newFilters);
                  setPage(0);
                }}
                filterMode={
                  isVFAdmin ? FilterMode.VFAdminCuratorWorkspace : FilterMode.CuratorWorkspace
                }
                statusFilterOptions={curationStatuses}
                resultsByFilter={resultsByFilter}
                dmoAreas={dmoAreas}
              />
            }
          />
        )
      }
    >
      <div className="h-100 bg-light">
        <Switch>
          <Route path={`${path}/edit/:productId`}>
            <CuratorProductForm companyId={companyId} setShowNotification={setShowNotification} />
          </Route>
          <ViewProductContainer>
            <ProductViewer>
              <Route path={`${path}`}>
                {showProductList && (
                  <CuratorProductList
                    ref={listElement}
                    products={products}
                    selectedProductId={selectedProductId}
                    loading={productsLoading}
                    hasNoDMOAreas={!isVFAdmin && dmoAreas?.length === 0}
                    companyId={companyId}
                    header={
                      <>
                        <FilteredListHeader
                          orderBy={orderBy}
                          onSortOrderChange={(orderBy: OrderBy) => setOrderBy(orderBy)}
                          onShowFiltersClick={() => {
                            setShowMobileFilters(true);
                          }}
                          headerText={t("curatorWorkspace.heading")}
                        />
                        <MobileFiltersModal
                          show={showMobileFilters}
                          onClose={() => {
                            setShowMobileFilters(false);
                          }}
                          resultCount={productsLength}
                        >
                          <Filters
                            initialFilters={defaultFilters}
                            filterType={FilterType.MobileFilters}
                            filters={filters}
                            onFilterChange={(newFilters) => {
                              setFilters(newFilters);
                              setPage(0);
                            }}
                            filterMode={
                              isVFAdmin
                                ? FilterMode.VFAdminCuratorWorkspace
                                : FilterMode.CuratorWorkspace
                            }
                            statusFilterOptions={curationStatuses}
                            resultsByFilter={resultsByFilter}
                            dmoAreas={dmoAreas}
                          />
                        </MobileFiltersModal>
                      </>
                    }
                    paging={
                      <Paging
                        productsLength={productsLength}
                        totalProductCount={totalProductCount}
                        productsLoading={productsLoading}
                        page={page}
                        setPage={setPage}
                        selectedAmountOption={selectedAmountOption}
                        setSelectedAmountOption={setSelectedAmountOption}
                      />
                    }
                  />
                )}
                <Route path={`${path}/:productId`}>
                  <ViewProduct
                    viewMode="dmo"
                    showNotification={showNotification}
                    onNotificationClose={onNotificationClose}
                    renderComments={({ hide, ...args }) => {
                      return !hide && <Comments {...args} />;
                    }}
                    renderToolbar={(args) => {
                      return (
                        <ViewProductCuratorToolbar
                          productsQuery={productsQuery}
                          companyId={companyId}
                          {...args}
                        />
                      );
                    }}
                  />
                </Route>
              </Route>
            </ProductViewer>
          </ViewProductContainer>
        </Switch>
      </div>
    </Page>
  );
};

export default CuratorWorkspace;
