import { useKeycloak } from "@react-keycloak/web";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CompanyInformationValues, UserRole } from "../../types";
import { getErrorCount } from "../../utils/formUtils";
import ConfirmButton from "../ConfirmButton";
import Notification from "../Notification";
import Toolbar from "../Toolbar";

type CompanyInformationToolbarProps = {
  onReset: () => void;
};

const CompanyInformationToolbar = ({ onReset }: CompanyInformationToolbarProps) => {
  const { t } = useTranslation();
  const [keycloak] = useKeycloak();
  const [show, setShow] = useState(false);

  const {
    errors,
    formState: { isSubmitting },
  } = useFormContext<CompanyInformationValues>();

  const canEditCompany = keycloak.hasResourceRole(UserRole.ManageCompany);

  const errorCount = getErrorCount(errors);

  return (
    <Toolbar
      notification={
        errorCount > 0 ? (
          <Notification type="danger" className="m-0">
            <div>{t("productInfo.validationErrorNotification", { errorCount })}</div>
          </Notification>
        ) : undefined
      }
    >
      <ConfirmButton
        id="discard-company-changes"
        confirmStyle="info"
        showIcon
        headerContent={t("productInfo.discardPopoverHeader")}
        popOverContent={<p className="pb-3">{t("productInfo.discardPopoverDescription")}</p>}
        confirmButtonContent={t("common.discardChanges")}
        triggerButtonContent={t("common.discardChanges")}
        onConfirmClick={() => {
          onReset();
        }}
        show={show}
        closePopover={() => setShow(!show)}
      />
      <Button variant="primary" type="submit" className="ml-4" disabled={!canEditCompany}>
        <div className="d-flex align-items-center">
          {isSubmitting && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
          <div className={isSubmitting ? "ml-2" : ""}>{t("companyInfo.save")}</div>
        </div>
      </Button>
    </Toolbar>
  );
};

export default CompanyInformationToolbar;
