import { Button, Form } from "react-bootstrap";
import {
  FormSectionSubHeader,
  FormSubDescription,
  FormSubSection,
  FormSubSectionContent,
} from "../FormSection";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { HintAndCounter } from "./HintAndCounter";
import { ProductPublishMode } from "../../types";
import Separator from "../Separator";
import { VerifiedLinkFormInput } from "./VerifiedLinkFormInput";
import { isDmo } from "../../utils/functions";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";

const MAX_NAME_LENGTH = 250;
const emptyElement = {
  title: "",
  url: "",
};

const VideoSection = ({
  publishMode,
  isProductGroup,
}: {
  publishMode: ProductPublishMode;
  isProductGroup: boolean;
}) => {
  const { t } = useTranslation();
  const { register, errors, watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: "videos",
    keyName: "videoId",
  });
  const [keycloak] = useKeycloak();
  const userIsDmo = isDmo(keycloak);

  useEffect(() => {
    if (fields.length === 0) {
      setValue("videos", [emptyElement], { shouldDirty: false });
    }
  }, [fields, setValue]);

  return (
    <FormSubSection>
      <FormSubDescription isLastRow>
        <FormSectionSubHeader>{t("productInfo.videoSectionHeader")}</FormSectionSubHeader>
        {!isProductGroup && <p>{t("productInfo.videoSectionDescription")}</p>}
      </FormSubDescription>
      <FormSubSectionContent isLastRow>
        {fields.map((video, index) => (
          <div key={`${video.videoId}`}>
            {index !== 0 && !isProductGroup && (
              <div>
                <Separator />
                <div className="text-right">
                  <Button
                    variant="ghost"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    {t("productInfo.delete")}
                  </Button>
                </div>
              </div>
            )}
            <VerifiedLinkFormInput
              controlId={`${video.videoId}_videoLink`}
              name={`videos[${index}].videoLink`}
              defaultValue={video.videoLink}
              isDmo={userIsDmo}
              publishMode={publishMode}
              labelKey={"productInfo.videoUrlLabel"}
              errorKey={errors.videos?.[index]?.videoLink?.url?.message}
              hintKey={"productInfo.videoUrlHint"}
              required={!!watch(`videos[${index}].title`)}
              disabled={isProductGroup}
            />
            <Form.Group controlId={`${video.videoId}_title`}>
              <Form.Label>{t("productInfo.videoTitleLabel")}</Form.Label>
              <Form.Control
                type="text"
                name={`videos[${index}].title`}
                isInvalid={!!errors.videos?.[index]?.title}
                ref={register({
                  maxLength: {
                    value: MAX_NAME_LENGTH,
                    message: t("validationErrors.textTooLong", { max: MAX_NAME_LENGTH }),
                  },
                  required: {
                    value: !!watch(`videos[${index}].videoLink.url`),
                    message: t("validationErrors.required"),
                  },
                })}
                defaultValue={video.title}
                disabled={isProductGroup}
              />
              <HintAndCounter
                hintText={t("productInfo.videoTitleLabelHint")}
                maxLength={MAX_NAME_LENGTH}
                currentLength={watch(`videos[${index}].title`)?.length ?? 0}
                error={errors.videos?.[index]?.title}
              />
            </Form.Group>
          </div>
        ))}
        {!isProductGroup && (
          <Button variant="ghost" onClick={() => append(emptyElement)}>
            + {t("productInfo.videoSectionAddLink")}
          </Button>
        )}
      </FormSubSectionContent>
    </FormSubSection>
  );
};

export default VideoSection;
