import Keycloak from "keycloak-js";
import type { KeycloakConfig } from "keycloak-js";

import { appendSlashToUrl } from "./utils/functions";

const KeyCloakConfig: KeycloakConfig = {
  realm: String(process.env.REACT_APP_KEYCLOAK_REALM_NAME),
  url: `${appendSlashToUrl(String(process.env.REACT_APP_KEYCLOAK_SERVER_URL))}auth`,
  clientId: String(process.env.REACT_APP_KEYCLOAK_REALM_CLIENTID),
};

const keycloak = Keycloak(KeyCloakConfig);

export default keycloak;
