import React, { useEffect } from "react";
import getCompanyDatahubType, {
  GetCompanyDatahubTypeResult,
  GetCompanyDatahubTypeVariables,
} from "../graphqlQueries/getCompanyDatahubType";
import {
  getHasuraRoleContext,
  isB2BUser,
  isCompanyDmo,
  isDmoOrRequestedDmo,
  isTcOrRequestedTc,
  isVisitFinlandAdmin,
} from "../utils/functions";

import B2BDashboard from "../components/dashboard/B2BDashboard";
import { DMODashboardWrapper } from "../components/dashboard/DMODashboardWrapper";
import DashboardNotifications from "../components/dashboard/DashboardNotifications";
import Page from "./Page";
import { TCDashboard } from "../components/dashboard/TCDashboard";
import { UserRole } from "../types";
import VFAdminDashboard from "../components/dashboard/VFAdminDashboard";
import { useKeycloak } from "@react-keycloak/web";
import { useLazyQuery } from "@apollo/client";
import { userInfoVar } from "../utils/ApolloCache";
import DefaultDashboard from "../components/dashboard/DefaultDashboard";

type DashboardProps = {
  companyId?: string;
};

const Dashboard: React.FunctionComponent<DashboardProps> = ({ companyId }) => {
  const [keycloak] = useKeycloak();

  const userInfo = userInfoVar();

  const isVFAdmin = isVisitFinlandAdmin(keycloak);
  const isB2B = isB2BUser(userInfo);
  const isDmoOrApplyingDmo = isDmoOrRequestedDmo(keycloak, userInfo);
  const isTcOrApplyingTc = isTcOrRequestedTc(keycloak, userInfo);

  const [getCompanyDatahubTypeQuery, { data: companyData }] = useLazyQuery<
    GetCompanyDatahubTypeResult,
    GetCompanyDatahubTypeVariables
  >(getCompanyDatahubType, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (companyId) {
      void getCompanyDatahubTypeQuery({
        variables: {
          companyId: companyId,
        },
        context: getHasuraRoleContext(UserRole.ViewCompany),
      });
    }
  }, [getCompanyDatahubTypeQuery, companyId]);

  const getDashboard = () => {
    if (isB2B) {
      return <B2BDashboard />;
    } else if (isVFAdmin) {
      return <VFAdminDashboard />;
    } else if (isDmoOrApplyingDmo && isCompanyDmo(companyData?.companyByPk?.datahubType)) {
      return companyId ? <DMODashboardWrapper companyId={companyId} /> : null;
    } else if (isTcOrApplyingTc) {
      return companyId ? <TCDashboard companyId={companyId} /> : null;
    } else {
      return <DefaultDashboard />;
    }
  };

  return (
    <Page>
      {!isB2B && <DashboardNotifications />}
      {getDashboard()}
    </Page>
  );
};

export default Dashboard;
