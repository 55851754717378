import {
  CurationStatus,
  Language,
  ProductCurationStatus,
  ProductNotificationFilter,
  ProductStatus,
} from "../types";

import { QueryFilterVariables } from "../utils/filterUtils";
import { capitalize } from "../utils/functions";

export const defaultCurationFilter = `productCurations: {
  curationStatus: { _in: $curationStatus },
  productCurationStatus: {_in: $productCurationStatus }
}`;
export const newCurationFilter = `productCurations: {
  curationStatus: { _in: [${CurationStatus.Pending}] },
  productCurationStatus: {_in: [${ProductCurationStatus.New}] }
}`;
export const updatedCurationFilter = `productCurations: {
  curationStatus: { _in: [${CurationStatus.Pending}] },
  productCurationStatus: {_in: [${ProductCurationStatus.Updated}] }
}`;
export const pendingCurationFilter = `productCurations: {
  curationStatus: { _in: [${CurationStatus.Pending}] },
  productCurationStatus: {_in: [${ProductCurationStatus.ChangesRequested}] }
}`;
export const approvedCurationFilter = `productCurations: {
  curationStatus: { _in: [${CurationStatus.Approved}] }
}`;
export const approvedAndUpdatedCurationFilter = `productCurations: {
  curationStatus: { _in: [${CurationStatus.Approved}] },
  productCurationStatus: {_in: [${ProductCurationStatus.Updated}] }
}`;
export const allCurationFilter = `productCurations: {
  curationStatus: { _in: [${CurationStatus.Approved}, ${CurationStatus.Pending}] }
}`;
export const draftCurationFilter = `status: {_in: [${ProductStatus.Draft}]}`;
export const allCompanyProducts = `status: {_in: [${ProductStatus.Draft}, ${ProductStatus.Published}]}`;
export const productCurationPendingFilter = `status: {_in: [${ProductStatus.Published}]}, productCurations: {curationStatus: { _in: [${CurationStatus.Pending}] }}`;

const getFilters = (curationFilter: string, certificate: string) => {
  return `
  {
    type: { _in: $type }
    _and: [
      {
        _or: [
          { productInformations: { name: { _ilike: $search }}}
          { productInformations: { description: { _ilike: $search }}}
          { company: { businessName: { _ilike: $search }}}
        ]
      }
      { _or: [{ _not: { productTags: {}}}, { productTags: { tag: { _in: $tags }}}]}

    ]
    productAvailableMonths: { month: { _in: $productAvailableMonths }}
    productTargetGroups: { targetGroupId: { _in: $targetGroups }}
    productInformations: { language: { _in: $languages }}
    companyId: { _in: $companies }
    deletedAt: { _is_null: true }
    accessible: { _eq: $accessible }
    ${curationFilter}
    ${certificate}
  }
  `;
};

const getStatusFilters = (filters: QueryFilterVariables) => {
  if (filters.curationStatus || filters.productCurationStatus || filters.status) {
    return `productCurations: {
      ${filters.curationStatus ? "curationStatus: { _in: $curationStatus }" : ""}
      ${
        filters.productCurationStatus ? "productCurationStatus: {_in: $productCurationStatus }" : ""
      }
    }
    ${filters.status ? "status: {_in: $status}" : ""}`;
  }

  return "";
};

const getNotificationFilters = (notificationFilter: string) => {
  switch (notificationFilter) {
    case ProductNotificationFilter.BrokenLink:
      return `_or: [
        { productInformations: { verifiedProductLink: { invalidAt: { _is_null: false } } } }
        { productInformations: { verifiedWebshopLink: { invalidAt: { _is_null: false } } } }
        { productVideos: { verifiedVideoLink: { invalidAt: { _is_null: false } } } }
      ]`;
    case ProductNotificationFilter.NewComment:
      return `productComments: { seenByTcAt: { _is_null: true } }`;

    default:
      return "";
  }
};

const getSearchAndNotificationFilters = (
  notifications: string | undefined,
  search: string | undefined
) => {
  const searchQuery = `
      _or: [
        { productInformations: { name: { _ilike: $search } } }
        { productInformations: { description: { _ilike: $search } } }
        { company: { businessName: { _ilike: $search } } }
      ]
    `;

  if (search && notifications) {
    return `_and: [{${searchQuery}}{${getNotificationFilters(notifications)}}]`;
  }

  if (search) {
    return searchQuery;
  }

  if (notifications) {
    return getNotificationFilters(notifications);
  }

  return "";
};

const getLanguageFilters = (selectedLanguages: Language[], byAllLanguages: boolean | undefined) => {
  const mainQuery = `productInformations: {language: {_in: $languages}}`;
  if (selectedLanguages.length > 1 && byAllLanguages) {
    return `_and: [${selectedLanguages
      .map((l: string) => `{${mainQuery.replace("$languages", l)}}`)
      .join(",")}]`;
  } else {
    return selectedLanguages.length > 0 ? mainQuery : "";
  }
};

const getProductQueryFilters = (
  filters: QueryFilterVariables,
  statusFilter?: string,
  companyId?: string,
  notDeleted?: boolean
) => {
  return `
  {
    ${filters.type ? "type: { _in: $type }" : ""}
    ${filters.tags ? "productTags: { tag: { _in: $tags } }" : ""}
    ${filters.targetGroups ? "productTargetGroups: { targetGroupId: { _in: $targetGroups } }" : ""}
    ${filters.languages ? getLanguageFilters(filters.languages, filters.byAllLanguages) : ""}
    ${filters.cities ? "postalAddresses: { postalArea: { city: { id: { _in: $cities } } } }" : ""}
    ${filters.accessible ? "accessible: { _eq: $accessible }" : ""}
    ${filters.months ? "productAvailableMonths: { month: { _in: $months } }" : ""}
    ${filters.certificates ? "productCertificates: { certificate: {_in: $certificates} }" : ""}
    ${filters.externalSource ? `externalSource: { _eq: $externalSource }` : ""}
    ${!statusFilter ? getStatusFilters(filters) : statusFilter}
    ${getSearchAndNotificationFilters(filters.productNotification, filters.search)}
    ${companyId ? `companyId: { _eq: $companyId }` : ""}
    ${notDeleted ? `deletedAt: { _is_null: true }` : ""}
  }
  `;
};

const getQueryName = (baseName: string, filters: QueryFilterVariables) => {
  const activeFilters = Object.keys(filters).reduce((acc, key) => acc + capitalize(key), "");

  return activeFilters ? baseName.concat("Filter", activeFilters) : baseName;
};

export { getProductQueryFilters, getQueryName };

export default getFilters;
