import { AggregateCount, OrderBy } from "../types";
import { getProductQueryFilters, getQueryName } from "./productFilters";

import { HasuradbProduct } from "./getProduct";
import { QueryFilterVariables } from "../utils/filterUtils";
import { gql } from "@apollo/client";

const buildPublishedProductsForProductGroupQuery = (filters: QueryFilterVariables) => {
  return gql`
    query ${getQueryName("getPublishedProducts", filters)}(
      $orderBy: order_by!
      $offset: Int!
      $limit: Int!
      ${filters.type ? "$type: [hasuradb_product_types_enum!]!" : ""}
      ${filters.tags ? "$tags: [String!]!" : ""}
      ${filters.search ? "$search: String!" : ""}
      ${filters.targetGroups ? "$targetGroups: [hasuradb_target_groups_enum!]!" : ""}
      ${filters.languages ? "$languages: [hasuradb_languages_enum]" : ""}
      ${filters.cities ? "$cities: [uuid!]!" : ""}
      ${filters.accessible ? "$accessible: Boolean!" : ""}
      ${filters.months ? "$months: [hasuradb_months_enum!]!" : ""}
      ${filters.certificates ? "$certificates: [String!]!" : ""}
    ) {
      product(
        where: ${getProductQueryFilters(filters)}
        order_by: { updatedAt: $orderBy }
        limit: $limit
        offset: $offset
      ) { 
        id
          type
          accessible
          duration
          durationType
          businessHours {
            id
            default {
              id
              weekday
              opens
              closes
              open
            }
            exceptions(order_by: { start: asc }) {
              id
              start
              end
              openingHours {
                id
                weekday
                opens
                closes
                open
                date
              }
            }
          }
          socialMedia {
            id
            companySocialMedia
            socialMediaLinks {
              id
              linkId
              linkType
              socialMediaId
              verifiedLink {
                id
                url
                userVerifiedAt
                userVerifiedBy
              }
            }
          }
          externalSource
          productImages(order_by: { orderIndex: asc }) {
            id
            largeUrl
            originalUrl
            originalHeight
            originalWidth
            orientation
            thumbnailUrl
            altText
            copyright
            filename
            coverPhoto
          }
          productVideos {
            id
            title
            verifiedVideoLink {
              id
              url
            }
          }
          productTargetGroups {
            id
            targetGroupId
          }
          productTags {
            id
            tag
          }
          productInformations {
            id
            name
            description
            language
            verifiedProductLink {
              id
              url
            }
            verifiedWebshopLink {
              id
              url
            }
          }
          company {
            id
            businessName
            businessHoursId
            socialMediaId
          }
          contactDetails {
            id
            phone
            email
          }
          productAvailabilities {
            id
            endDate
            startDate
          }
          productAvailableMonths {
            id
            month
          }
          productPricings {
            id
            fromPrice
            toPrice
            pricingUnit
            pricingType
          }
          productAvailabilityLanguages {
            id
            language
          }
          postalAddresses {
            id
            city
            postalCode
            streetName
            location
          }
          productCertificates {
            id
            certificate
          }
          productCapacities {
            id
            min
            max
          }
      }
      productAggregate(
        where: ${getProductQueryFilters(filters)}
      ) {
        aggregate {
          count
        }
      }
    }
`;
};

export type GetPublishedProductsForProductGroupResult = {
  product: HasuradbProduct[];
  productAggregate: AggregateCount;
};

export type GetPublishedProductsForProductGroupVariables = {
  orderBy: OrderBy;
  limit: number;
  offset: number;
  search?: string;
  type?: string;
  tags?: string[];
  months?: string[];
  targetGroups?: string[];
  cities?: string[];
  certificates?: string[];
  accessible?: boolean;
};

export default buildPublishedProductsForProductGroupQuery;
