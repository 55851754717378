import { useQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { Accordion, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import getUserInfoLocal, { UserInfoData } from "../../graphqlQueries/getUserInfoLocal";
import { ReactComponent as Person } from "../../icons/person.svg";
import AccordionContent from "../accordion/AccordionContent";
import AccordionIcon from "../accordion/AccordionIcon";
import AccordionToggle from "../accordion/AccordionToggle";
import { DropdownContainer, DropdownContent, PlainDropdownMenu, PlainToggle } from "../Dropdown";
import LanguageDropdown from "../LanguageDropdown";
import { removeAllFiltersFromSessionStorage } from "../../utils/filterUtils";

const renderAccordionIcon = (open: boolean) => (
  <AccordionIcon open={open} style={{ marginRight: "23px" }} />
);

const UserDropdown = () => {
  const [keycloak] = useKeycloak();
  const { loading, data } = useQuery<UserInfoData>(getUserInfoLocal);

  const { t } = useTranslation();

  return (
    <Dropdown as={DropdownContainer} style={{ height: "65px" }} data-cy="user-menu-dropdown">
      <Dropdown.Toggle as={PlainToggle} id="user-dropdown">
        <div className="mr-md-3">
          <Person />
        </div>
        <div className="d-none d-md-block">
          {!loading && data?.userInfo && <div data-cy="user-name">{data.userInfo.fullName}</div>}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu as={PlainDropdownMenu} popperConfig={{ strategy: "fixed" }}>
        <Accordion data-cy="language-dropdown">
          <AccordionToggle eventKey="0" renderIcon={renderAccordionIcon}>
            <AccordionContent>{t("common.changeLanguage")}</AccordionContent>
          </AccordionToggle>
          <Accordion.Collapse eventKey="0">
            <LanguageDropdown signedIn />
          </Accordion.Collapse>
        </Accordion>
        <Dropdown.Item
          onClick={() => {
            removeAllFiltersFromSessionStorage();
            keycloak?.logout();
          }}
        >
          <DropdownContent>{t("common.logout")}</DropdownContent>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserDropdown;
