import { ArrayField, useFormContext } from "react-hook-form";
import { Language, ProductDetails, ProductPublishMode } from "../../../types";

import { Form } from "react-bootstrap";
import { HintAndCounter } from "../HintAndCounter";
import Icon from "../../Icon";
import InputWithHighlights from "../InputWithHighlights";
import React from "react";
import { VerifiedLinkFormInput } from "../VerifiedLinkFormInput";
import { getRequiredValidator } from "../../../utils/formUtils";
import { isDmo } from "../../../utils/functions";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";

type ProductLanguageVersionProps = {
  publishMode: ProductPublishMode;
  currentLanguage: Language;
  fields: Partial<ArrayField<ProductDetails, "id">>[];
  isProductGroup: boolean;
};

const MAX_TEXT_INPUT_LENGTH = 2000;
const MAX_NAME_LENGTH = 200;

const ProductLanguageVersion: React.FunctionComponent<ProductLanguageVersionProps> = ({
  publishMode,
  currentLanguage,
  fields,
  isProductGroup,
}) => {
  const { register, errors, getValues, watch } = useFormContext();
  const { t } = useTranslation();
  const [keycloak] = useKeycloak();
  const userIsDmo = isDmo(keycloak);
  const { details: currentDetails } = getValues();

  return (
    <div>
      {fields.map((field, index: number) => {
        const prodDescCount: number = currentDetails?.[index]?.productDescription?.length ?? 0;

        /**
         * Render all fields into the DOM to preserve data but
         * show only those associated with the active language
         */
        return (
          <div
            style={{ display: field.language !== currentLanguage ? "none" : "initial" }}
            key={`language-field-${field.id}`}
          >
            <Form.Control
              type="hidden"
              name={`details[${index}].language`}
              defaultValue={field.language}
              ref={register()}
            />
            <Form.Group controlId={`${field.id}.productName`}>
              <Form.Label>{t("productInfo.productName")} *</Form.Label>
              <Form.Control
                type="text"
                name={`details[${index}].productName`}
                key={field.id}
                isInvalid={!!errors.details?.[index]?.productName}
                disabled={isProductGroup}
                ref={register({
                  maxLength: {
                    value: MAX_NAME_LENGTH,
                    message: t("validationErrors.textTooLong", { max: MAX_NAME_LENGTH }),
                  },
                  validate: {
                    required: getRequiredValidator(publishMode, fields.length < 1, t),
                  },
                })}
                defaultValue={currentDetails?.[index]?.productName || field.productName}
              />
              <HintAndCounter
                hintText={t("productInfo.productNameHint")}
                maxLength={MAX_NAME_LENGTH}
                currentLength={watch(`details[${index}].productName`)?.length ?? 0}
                error={errors.details?.[index]?.productName}
              />
            </Form.Group>
            <Form.Group controlId={`${field.id}.productDescription`}>
              <Form.Label>{t("productInfo.productDescription")} *</Form.Label>
              <InputWithHighlights
                name={`details[${index}].productDescription`}
                defaultValue={
                  currentDetails?.[index]?.productDescription || field.productDescription || ""
                }
                isInvalid={!!errors.details?.[index]?.productDescription}
                maxLength={MAX_TEXT_INPUT_LENGTH}
                rules={{
                  maxLength: {
                    value: MAX_TEXT_INPUT_LENGTH,
                    message: t("validationErrors.textTooLong", { max: MAX_TEXT_INPUT_LENGTH }),
                  },
                  validate: {
                    required: getRequiredValidator(publishMode, false, t),
                  },
                }}
              />

              {errors.details?.[index]?.productDescription ? (
                <p className="text-danger text-small">
                  <Icon name="warning-triangle" size="small" />
                  {errors.details?.[index].productDescription.message}
                </p>
              ) : (
                <p className="text-small color-gray-600">
                  {t("productInfo.productDescriptionHint", { count: prodDescCount })}
                </p>
              )}
            </Form.Group>

            <VerifiedLinkFormInput
              controlId={`${field.id}.productLink`}
              name={`details[${index}].productLink`}
              defaultValue={currentDetails?.[index].productLink || field.productLink}
              isDmo={userIsDmo}
              required={!isProductGroup}
              publishMode={publishMode}
              labelKey={"productInfo.productUrl"}
              hintKey={"productInfo.productUrlHint"}
              errorKey={errors.details?.[index]?.productLink?.url?.message}
              disabled={isProductGroup}
            />
            <VerifiedLinkFormInput
              controlId={`${field.id}.webshopLink`}
              name={`details[${index}].webshopLink`}
              defaultValue={currentDetails?.[index].webshopLink || field.webshopLink}
              isDmo={userIsDmo}
              publishMode={publishMode}
              labelKey={"productInfo.webshopUrl"}
              hintKey={"productInfo.webshopUrlHint"}
              errorKey={errors.details?.[index]?.webshopLink?.url?.message}
              disabled={isProductGroup}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ProductLanguageVersion;
