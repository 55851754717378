import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  NormalizedCacheObject,
  concat,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";

import Loading from "../components/Loading";
import { cache } from "./ApolloCache";
import { checkToken, getGraphQLEndpoint } from "./functions";

const getBearerToken = async (keycloak: Keycloak.KeycloakInstance) => {
  const { tokenOk, error } = await checkToken(keycloak);
  if (error) {
    console.error("Token refresh failed", error);
  }
  return tokenOk ? `Bearer ${keycloak.token}` : null;
};

const ApolloServiceProvider: React.FC = ({ children }) => {
  const [keycloak] = useKeycloak();
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(
    null
  );

  useEffect(() => {
    if (keycloak) {
      const httpLink = new HttpLink({ uri: getGraphQLEndpoint() });

      const authMiddleware = setContext(async (_, { headers }) => {
        // add the authorization to the headers
        return {
          headers: {
            ...headers,
            authorization: await getBearerToken(keycloak),
          },
        };
      });

      const client = new ApolloClient({
        cache: cache,
        link: concat(authMiddleware, httpLink),
      });

      setApolloClient(client);
    }
  }, [keycloak]);

  if (!apolloClient) {
    return <Loading />;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloServiceProvider;
